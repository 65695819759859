import React, { Component } from "react";

import { getGallery } from "../Services.js";
import GalleryCard from "../component/GalleryCard.js";
import { withRouter } from "react-router-dom";
import { getEmbedThemeName, getEmbedThemeInfo, getEmbedExperienceText } from "../theme/Loader";
// import CircularArray from "../model/Circular";
import _ from 'lodash';

import "./gallery-scroll.css";
import { triggerSAEvent } from "../utils.js";
import { VideoPlayer } from '../component/GalleryCard'
// import { VideoPlayer } from './../component/ImpactGallery/VideoPlayer/VideoPlayer';

export default class GalleryScroll extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {
      posts: [],
      status: true,
      fadeIn: false,
      fadeOut: false,
      postsToRender: [],
      end: false,
      selectedPost: null,
    };
    this.myRef = React.createRef();
  }

  getCurrentTheme = () => {
    return { name: getEmbedThemeName(), info: getEmbedThemeInfo() };
  };

  apiApprovedPhotos = (animate) => {
    let {
      gallery: { apiLimit, contentType, filter, format },
    } = getEmbedThemeInfo();
    let params = {
      limit: apiLimit,
      contentType,
      filter,
      format,
      from: null
    };
    getGallery(
      (posts) => {
        // let circularPosts = new CircularArray(posts);
        // fade in
        // console.log('in gallery reponse', posts)
        this.setState({ posts, end: false, isLoading: false }, () => {
          if (animate) {
            this.toggleAnimationClass();
            return;
          }
        });
      },
      () => { this.setState({ end: true, isLoading: false }) },
      params
    );
  };

  fetchApi = (animate) => {
    this.apiApprovedPhotos(animate);
  };

  scheduleApi = (refreshRate) => {
    this.interval = setInterval(() => this.fetchApi(), refreshRate * 1000);
  };

  // scheduleAnimation = (displayRefreshRate) => {
  //   this.animationInterval = setInterval(
  //     () => this.toggleAnimationClass(),
  //     displayRefreshRate * 1000
  //   );
  // };

  animate = () => {
    // let {
    //   gallery: { displayLimit = 15 },
    // } = getEmbedThemeInfo();
    // let postsToRender = [];
    // for (let i = 0; i < displayLimit; i++) {
    //   postsToRender.push(this.state.posts.current());
    //   this.state.posts.next();
    // }
    this.setState({ postsToRender: this.state.posts, fadeIn: true, fadeOut: false });
  };

  toggleAnimationClass = () => {
    this.setState({ fadeIn: false, fadeOut: true }, () =>
      setTimeout(this.animate, 500)
    );
  };

  synchronousSetState = (state) => {
    return new Promise((resolve, reject) => {
      this.setState(state, () => resolve());
    });
  }

  handleScroll = async () => {
    // const el = window.document.scrollingElement || window.document.documentElement; // needed for safari
    const el = this.myRef.current;
    // console.log('---el.scrollTop', el.scrollTop, el.scrollHeight, el.clientHeight)
    if (el.scrollTop > 100 && !this.state.scrolled) {
      this.setState({ scrolled: true });
      return;
    }
    if (el.scrollTop <= 100 && this.state.scrolled) {
      this.setState({ scrolled: false });
      return
    }
    let offset =
      // el.scrollHeight - el.scrollTop === el.clientHeight;
      el.scrollHeight - el.scrollTop < 1.7 * el.clientHeight;
    // if (offset <= 800 && !this.state.isLoading) {
    if (offset && !this.state.isLoading) {
      let lastPostId = _.get(_.last(this.state.posts), 'id');
      !this.state.end && await this.synchronousSetState({ isLoading: true });
      let {
        info: {
          gallery: { apiLimit, contentType, filter, format },
        },
      } = this.getCurrentTheme();
      let params = {
        limit: apiLimit,
        contentType,
        filter,
        format,
        anchor: lastPostId,
        temporality: 'older'
      };
      !this.state.end && this.fetchApiData(params);
    }
  };

  fetchApiData = (params) => {

    getGallery(
      (posts) => {
        this.setState(prevState => ({ posts: _.concat(prevState.posts, posts), isLoading: false, end: false }), this.animate);
      },
      () => { console.log('in error 204'); this.setState({ end: true, isLoading: false }) },
      params
    );
  }

  componentDidMount() {
    // let {
    //   info: {
    //     gallery: { refresh, apiRefreshRate, displayRefreshRate },
    //   },
    // } = this.getCurrentTheme();
    this.fetchApi(true);
    this.props.amplitude && this.props.amplitude.dispatchAnalyticsEvent('LOADED GALLERY')
    // window.addEventListener('scroll', this.handleScroll);
    // if (refresh) {
    //   // this.scheduleApi(apiRefreshRate);
    //   // this.scheduleAnimation(displayRefreshRate);
    //   return;
    // }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.animationInterval);
  }

  selectVideoPost = (post) => {
    // console.log(post);
    this.setState({ selectedPost: post })
  }

  getItems() {
    // let noOfPosts = this.getCurrentTheme().info.gallery_count || 3;
    // console.log('ksdjl');
    return this.state.postsToRender.map((post, i) => {
      return <GalleryCard key={post.getId() + i} post={post} selectPost={this.selectVideoPost} />;
    });
  }
  getText = (key) => {
    return getEmbedExperienceText('gallery-scroll', key);
  }


  back = () => {
    // console.log('-goback container-', this.props.prevExperience);
    if (!this.props.prevExperience) {
      return;
    }
    this.props.setExperience(this.props.prevExperience)
    this.props.setCategory('')
    triggerSAEvent(`back to lounge from gallery`)
  };

  tweetAction = () => {
    let hashtags = this.getText('galleryHashtag');
    // console.log('hashtags', hashtags)
    window.open(`https://twitter.com/intent/tweet?hashtags=${hashtags.join(',')}`, '', "height=400,width=400");
    triggerSAEvent(`post from gallery`)
  }
  resetToTop = () => {
    const el = this.myRef.current;
    el.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    // this.setState({scrolled: false})
  }
  componentDidCatch(error, errorInfo) {
    console.info(errorInfo);
  }
  close = () => {
    // console.log('in close');
    this.setState({ selectedPost: null })
  }

  getPosition = () => {
    // console.log('----', this.state.selectedPost.isPortrait())
    // landscape return top: 30%; left: 25%;
    // portrait return top: 18%; left: 33%;
    return this.state.selectedPost.isPortrait() ? { top: "18%", left: "33%" } : { top: "30%", left: "25%" };
  }

  render() {
    // console.log('in gallery', this.state.postsToRender)
    const title = this.getText("galleryTitle");
    // const title = this.getCurrentTheme().info.home["/gallery"] || "Gallery";
    if (this.state.postsToRender.length <= 0) {
      return (
        <div
          className={`gallery-scroll-parent-container ${this.state.fadeIn ? "fade-in" : ""
            } ${this.state.fadeOut ? "fade-out" : ""}`}
          style={{ width: '100vw', height: '100vh' }}
        >
          <div className="header">
            <div className="back" onClick={this.back}>
              <i className={`icon-fk ${this.getText('back-icon-fk')}`} onClick={this.back} />
              <div className='back-label'>{this.getText('back-text')}</div>
            </div>
            <div className="gallery-scroll-parent-titlte title">{title}</div>
            <div className='action' onClick={this.tweetAction}>
              <i className={this.getText('action-icon-fk')}></i>
              {this.getText('action-text')}
            </div>
          </div>
          <div className="spinner"></div>
        </div>
      )
    }
    return (
      <div
        className={`gallery-scroll-parent-container ${this.state.fadeIn ? "fade-in" : ""
          } ${this.state.fadeOut ? "fade-out" : ""}`}
      >
        <div className="header">
          <div className="back" onClick={this.back}>
            <i className={`icon-fk ${this.getText('back-icon-fk')}`} onClick={this.back} />
            <div className='back-label'>{this.getText('back-text')}</div>
          </div>
          <div className="gallery-scroll-parent-title title">{title}</div>
          <div className='action' onClick={this.tweetAction}>
            <i className={this.getText('action-icon-fk')}></i>
            {this.getText('action-text')}
          </div>
        </div>
        <div className={`scroll-to-top  ${this.state.scrolled ? '' : 'hide'}`} onClick={this.resetToTop}>

          <i className='icon-fk-up-1' />
        </div>
        <div className={`gallery-scroll-container`}
          id="idd"
        >
          {this.state.selectedPost &&
            <div className={`video-card-parent ${this.state.selectedPost.isPortrait() ? 'portrait' : 'landscape'}`} >
              <div className='video-card'>
                <div className='close' onClick={this.close}>
                </div>
                <VideoPlayer src={this.state.selectedPost.getVideoUrl()} />
              </div>
            </div>
          }
          <div
            className={`gallery-scroll-cards ${this.state.selectedPost ? 'blur' : ''}`}
            onScroll={this.handleScroll}
            ref={this.myRef}
          // onClick={() => { this.setState({ selectVideoPost: null }) }}
          >
            {this.getItems()}
          </div>
          {this.state.isLoading ? <div className="spinner"></div> : null}
        </div>
      </div>
      // </div>
    );
  }
}

export const withRouterGalleryScroll = withRouter(GalleryScroll);
